<template>
  <v-card color="primary" class="mx-3 my-4">
    <v-card-text class="white--text">
      <b>Durchgeführte Tests:</b> {{ testCount }} <br />
      <b>Durchgeführte Antigen-Tests:</b> {{ antigenCount }} <br />
      <b>Durchgeführte PCR-Tests:</b> {{ pcrCount }} <br />
      <b>Stornierte Test:</b> {{ stornCount }} <br />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "TestsCard",

  data() {
    return {
      testCount: 0,
      antigenCount: 0,
      pcrCount: 0,
      stornCount: 0,
    };
  },

  mounted() {
    this.$http({
      method: "get",
      url: "/test/count",
    })
      .then((response) => {
        this.testCount = response.data.testCount;
        this.antigenCount = response.data.antigenCount;
        this.pcrCount = response.data.pcrCount;
        this.stornCount = response.data.stornCount;
      })
      .catch((err) => {
        this.errored = true;
        this.loading = false;
        console.log("AXIOS ERROR: ", err);
      });
  },
};
</script>
