<template>
  <v-container>
    <div class="d-flex justify-space-between my-5">
      <div class="v-text-field text-h4">Dashboard</div>
    </div>
    <div class="d-flex flex-row">
      <div>
        <tests-card v-if="user.isSuperAdmin"></tests-card>
      </div>
      <div>
        <v-card color="primary" dark @click="$router.push('/booking')">
          <v-card-title class="text-h5">Corona Test Buchen</v-card-title>

          <v-card-subtitle>Buchen Sie hier Ihren Antigen- order PCR-Test</v-card-subtitle>

          <v-card-actions>
            <v-btn text>
              Test Buchen
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
    <div v-if="user.isSuperAdmin" class="d-flex flex-row">
      <test-point-card v-for="testPoint in testPoints" :key="testPoint.id" :organisation="testPoint"></test-point-card>
    </div>
    <div class="home d-flex flex-wrap">
      <organisation-card
        v-for="organisation in user.organisations"
        :key="organisation.id"
        :orgid="organisation.id"
        :orgName="organisation.name"
        :streetName="organisation.streetName"
        :houseNumber="organisation.houseNumber"
        :zipCode="organisation.zipCode"
        :cityName="organisation.cityName"
      >
      </organisation-card>
    </div>
  </v-container>
</template>

<script>
import OrganisationCard from "../components/dashboard/OrganisationCard.vue";
import TestsCard from "../components/dashboard/TestsCard.vue";
import TestPointCard from "../components/dashboard/TestPointCard.vue";

export default {
  components: { OrganisationCard, TestsCard, TestPointCard },

  data() {
    return {
      testPoints: Object,
    };
  },

  mounted() {
    this.$http({
      method: "get",
      url: "/testpoint",
    })
      .then((response) => {
        this.testPoints = response.data;
      })
      .catch((err) => {
        this.errored = true;
        this.loading = false;
        console.log("AXIOS ERROR: ", err);
      });
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
</script>
