<template>
  <v-card class="mx-3 my-4">
    <v-card-title class="headline">
      {{ organisation.name }}
    </v-card-title>
    <v-list>
      <v-list-item class="mt-n5" v-for="appointment in organisation.appointments" :key="appointment.id">
        <v-list-item-content>
          <v-list-item-title
            >{{ displayDateWithTime(appointment.appointmentDate) }}
            <span v-if="getFreeSlots(appointment) == 0" class="red--text">
              {{ appointment.bookedTests }} ({{ appointment.amountOfPeople }})
            </span>
            <span v-else-if="getFreeSlots(appointment) < 3 && getFreeSlots(appointment) > 0" class="orange--text">
              {{ appointment.bookedTests }} ({{ appointment.amountOfPeople }})
            </span>
            <span v-else> {{ appointment.bookedTests }} ({{ appointment.amountOfPeople }}) </span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "TestPointCard",

  props: {
    organisation: Object,
  },

  data() {
    return {};
  },

  methods: {
    displayDateWithTime(timestamp) {
      return dayjs(timestamp).format("DD.MM.YYYY - HH:mm");
    },

    getFreeSlots(item) {
      return item.amountOfPeople - item.bookedTests;
    },
  },
};
</script>
