<template>
  <v-card class="mx-3 my-4">
    <v-card-title class="headline">
      {{ orgName }}
    </v-card-title>
    <v-card-text>
      {{ streetName }} {{ houseNumber }}, {{ zipCode }} {{ cityName }}
      <div class="text--primary mt-5" v-if="testCount">
        <b>Durchgeführte Tests:</b> {{ testCount }} <br />
        <b>Letzer Testtag:</b> {{ displayDate(lastTestDay) }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="primary" @click="linkToOrg"> Zum Unternehmen </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    orgid: Number,
    orgName: String,
    streetName: String,
    houseNumber: String,
    zipCode: Number,
    cityName: String,
    testCount: Number,
    lastTestDay: Number,
  },

  methods: {
    displayDate(timestamp) {
      return dayjs(timestamp).format("DD.MM.YYYY");
    },

    linkToOrg() {
      this.$router.push("/organisation/" + this.orgid);
    },
  },
};
</script>
